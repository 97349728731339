import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Button } from './buttons/button'
import FilterBar from './filterBar'
import CustomImage from './images/image'
import _, { isEmpty } from 'lodash'

const HeaderOverhaul = ({
  header,
  filterBar,
  filters,
  className,
  titleClassName,
  descriptionClassName,
  darkBackground,
  subtitle
}) => {
  const { title, description, icon } = header
  const router = useRouter()

  const { id } = router.query
  const button = router.asPath === `/job-offers/${id}` && id ? 'Apply Now' : null

  const hasFilter = router.asPath === '/seminars' || router.asPath === '/projects'

  const wrapperClassName = classNames(
    { [`${className}`]: className },
    {
      subtitle: subtitle
    },
    'header-overhaul-wrapper'
  )

  const titleClasses = classNames(
    {
      [`${titleClassName}`]: titleClassName
    },
    {
      'dark-background': darkBackground
    },
    {
      subtitle: subtitle
    },
    'header-overhaul-title'
  )

  const descriptionClasses = classNames(
    {
      [`${descriptionClassName}`]: descriptionClassName
    },
    {
      'dark-background': darkBackground
    },
    {
      subtitle: subtitle
    },
    'header-overhaul-description'
  )

  return (
    <div className={wrapperClassName}>
      <div className="header-overhaul-header">
        {!isEmpty(title.trim()) && (
          <div className="header-overhaul-title-container">
            {icon && icon.data && (
              <div className="header-overhaul-icon-container">
                <CustomImage
                  media={icon.data.attributes}
                  layout="fill"
                  objectFit="contain"
                  alt="header icon"
                  shimmer
                />
              </div>
            )}
            <div className={titleClasses}>{title}</div>
          </div>
        )}
        {description && <div className={descriptionClasses}>{description}</div>}
        {button && (
          <div className="header-overhaul-button-container">
            <Link href={`/contact?subject=${title}`}>
              <Button className="header-overhaul-button" text={button} />
            </Link>
          </div>
        )}
      </div>
      {hasFilter && <FilterBar filterBar={filterBar} filters={filters} />}
    </div>
  )
}

export default HeaderOverhaul
