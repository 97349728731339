import { useCallback, useState } from 'react'

export const useMergeState = (initialState = {}) => {
  const [value, setValue] = useState(initialState)

  const mergeState = useCallback(
    newState => {
      if (typeof newState === 'function') newState = newState(value)
      setValue({ ...value, ...newState })
    },
    [value]
  )

  return [value, mergeState]
}
