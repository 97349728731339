import { Tag } from 'antd'
import { useCallback } from 'react'

const filterToOptionsKey = {
  category: 'categories',
  technology: 'technologies',
  individual: 'individuals',
  industry: 'industries'
}

export const colorPresets = ['magenta', 'orange', 'geekblue', 'purple', 'green']

export const FilterTags = ({
  selectedFilters = [],
  filtersOptions,
  removeFilter,
  excludedFilters = []
}) => {
  const validFilters = Object.entries(selectedFilters).filter(
    ([key]) => !excludedFilters.includes(key)
  )

  const makeTagsIfExist = ([key, filters], index) => {
    return filters.reduce((acc, filterId) => {
      const option = filtersOptions[filterToOptionsKey[key]].find(
        ({ id }) => id === filterId
      )
      if (!option) return acc
      else {
        const action = () => removeFilter(key, filterId)
        const newTag = (
          <Tag
            key={filterId}
            color={colorPresets[index]}
            closable
            onClose={action}
            onClick={action}
          >
            {option.attributes.name}
          </Tag>
        )
        return [...acc, newTag]
      }
    }, [])
  }

  return validFilters.map(makeTagsIfExist)
}
