import { Select } from 'antd'
import { useIsMobile } from 'common/hooks'
import { useCallback, useState } from 'react'

const filterSort = (a, b) => a.label.localeCompare(b.label)
const filterOption = (input, option) =>
  option.label.toLowerCase().includes(input.toLowerCase())

const SelectMenuOptions = ({
  type,
  label,
  value,
  options,
  setValue,
  placeholder = 'please select',
  hideLabel = false,
  maxTagCount = undefined,
  maxTagPlaceholder = undefined
}) => {
  const [search, setSearch] = useState('')
  const isMobile = useIsMobile()
  const optionsForSelect = options.map(option => {
    return {
      label: [option.attributes.name, option.attributes.surname].join(' '),
      value: option.id
    }
  })

  const clearSearch = useCallback(() => {
    setSearch('')
  }, [setSearch])

  const handleChange = useCallback(
    newValue => {
      setValue({ [type]: newValue })
    },
    [setValue, type]
  )
  const handleFocus = e => {
    if (isMobile)
      e.target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
  }

  return (
    <div className="filter-bar-modal-selector">
      {!hideLabel && (
        <h3 type={5} className="filter-bar-modal-label">
          {label}
        </h3>
      )}
      <Select
        size="large"
        menuItemSelectedIcon={null}
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder={placeholder}
        onChange={handleChange}
        options={optionsForSelect}
        value={value}
        filterSort={filterSort}
        filterOption={filterOption}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={search ? <></> : maxTagPlaceholder}
        onFocus={handleFocus}
        searchValue={search}
        onSearch={setSearch}
        onBlur={clearSearch}
      />
    </div>
  )
}

export default SelectMenuOptions
