import { useEffect, useState } from 'react'

import { useFetch } from '../useFetch'

export const useMockUpFetch = ({ limit, mocked, endpoint }) => {
  const [state, setState] = useState(Array.from({ length: limit }).fill(mocked))
  const { data, fetchData } = useFetch()

  useEffect(() => {
    fetchData(endpoint)
  }, [limit, endpoint])

  useEffect(() => {
    if (!data) return
    setState(data)
  }, [data])

  return { state }
}
