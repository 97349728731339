import { SearchOutlined } from '@ant-design/icons'
import { Badge, Button, Input } from 'antd'
import { useMergeState } from 'common/hooks/useMergeState'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { getCategories, getIndividuals, getIndustries, getTechnologies } from './lib'
import SelectMenuOptions from './selectMenuOptions'
import { FilterTags, colorPresets } from './filterTags'

const checkLength = str => (str && str.length > 0 ? 1 : 0)
const checkArrayLength = arr => (arr ? arr.length : 0)

const selectorsFiltersEnabled = ({ category, technology, individual, industry }) =>
  checkArrayLength(category) +
  checkArrayLength(technology) +
  checkArrayLength(individual) +
  checkArrayLength(industry)

const allFiltersEnabled = ({ category, technology, individual, industry, title }) =>
  selectorsFiltersEnabled({ category, technology, individual, industry }) +
  checkLength(title)

const initialFilterOptionsObjects = {
  loading: false,
  categories: [],
  technologies: [],
  individuals: [],
  industries: []
}

const DEBOUNCE_TIMER = 300

const noTagsFilters = ['name', 'title']

const FilterBar = ({ filterBar, filters: { selectedFilters, handleChangeFilters } }) => {
  const { categorySearch, individualSearch, technologySearch, industrySearch } = filterBar
  const [filtersOptions, setFiltersOptions] = useMergeState(initialFilterOptionsObjects)

  const inputRef = useRef(null)
  const [searchValue, setSearchValue] = useState('')

  const handleChangeSelectors = useCallback(
    selector => {
      handleChangeFilters(selector)
    },
    [handleChangeFilters]
  )

  // Function to add for the display of the selectors in the filters
  const selectorsObject = useMemo(() => {
    const selectors = []
    const { categories, technologies, individuals, industries } = filtersOptions

    if (categorySearch && categories)
      selectors.push({
        type: 'category',
        value: selectedFilters.category,
        options: categories,
        setValue: handleChangeSelectors
      })

    if (technologySearch && technologies)
      selectors.push({
        type: 'technology',
        value: selectedFilters.technology,
        options: technologies,
        setValue: handleChangeSelectors
      })
    if (individualSearch && individuals)
      selectors.push({
        type: 'individual',
        label: 'author',
        value: selectedFilters.individual,
        options: individuals,
        setValue: handleChangeSelectors
      })
    if (industrySearch && industries)
      selectors.push({
        type: 'industry',
        value: selectedFilters.industry,
        options: industries,
        setValue: handleChangeSelectors
      })
    return selectors
  }, [
    filtersOptions,
    categorySearch,
    technologySearch,
    individualSearch,
    industrySearch,
    selectedFilters.category,
    selectedFilters.technology,
    selectedFilters.individual,
    selectedFilters.industry,
    handleChangeSelectors
  ])

  const handleOnChangeInput = useCallback(
    event => {
      handleChangeFilters({ title: event.target.value })
    },
    [handleChangeFilters]
  )

  const debouncedChangeHandler = useMemo(
    () => debounce(handleOnChangeInput, DEBOUNCE_TIMER),
    [handleOnChangeInput]
  )

  const changeHandler = useCallback(
    event => {
      setSearchValue(event.target.value)
      debouncedChangeHandler(event)
    },
    [setSearchValue, debouncedChangeHandler]
  )

  const resetFilters = useCallback(() => {
    setSearchValue('')
    handleChangeFilters({
      title: '',
      category: [],
      technology: [],
      individual: [],
      industry: []
    })
  }, [handleChangeFilters])

  const removeFilter = (key, filterId) => {
    const newFilters = {
      [key]: selectedFilters[key].filter(id => id !== filterId)
    }
    handleChangeFilters({ ...selectedFilters, ...newFilters })
  }

  useEffect(() => {
    const fetchFiltersOptions = async () => {
      setFiltersOptions({ loading: true })
      const categories = categorySearch ? await getCategories() : []
      const technologies = technologySearch ? await getTechnologies() : []
      const individuals = individualSearch ? await getIndividuals() : []
      const industries = industrySearch ? await getIndustries() : []

      setFiltersOptions({
        loading: false,
        categories,
        technologies,
        individuals,
        industries
      })
    }
    fetchFiltersOptions()
  }, [])

  return (
    <span className="filter-bar-wrapper">
      <div className="filter-bar-container">
        <Input
          className="filter-bar-input"
          id="title-input"
          placeholder="Search..."
          value={searchValue}
          onChange={changeHandler}
          size="large"
          prefix={<SearchOutlined className="filter-bar-search-icon" />}
          ref={inputRef}
          allowClear
        />
        <span className="filters-container">
          {selectorsObject.map((selector, index) => (
            <Badge
              key={selector.type}
              count={selector.value.length}
              className="filter-bar-selector"
              color={colorPresets[index]}
            >
              <SelectMenuOptions
                type={selector.type}
                label={selector.label}
                hideLabel
                placeholder={selector.label ?? selector.type}
                value={selector.value}
                options={selector.options}
                setValue={selector.setValue}
                maxTagCount={0}
                maxTagPlaceholder={() => <div>{selector.label ?? selector.type}</div>}
              />
            </Badge>
          ))}
          <Button
            type="link"
            onClick={resetFilters}
            className={
              allFiltersEnabled(selectedFilters) === 0
                ? 'clear-button hide-clear-button'
                : 'clear-button'
            }
          >
            Clear
          </Button>
        </span>
      </div>
      {selectorsFiltersEnabled(selectedFilters) > 0 && (
        <div className="filter-tags">
          <FilterTags
            selectedFilters={selectedFilters}
            filtersOptions={filtersOptions}
            removeFilter={removeFilter}
            excludedFilters={noTagsFilters}
          />
        </div>
      )}
    </span>
  )
}

export default FilterBar
