import { getStrapiURL } from 'utils/endpoints'

import { useMergeState } from '../useMergeState'

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  }
}

const initialState = { data: null, meta: null, error: null, isLoading: false }

export const useFetch = () => {
  const [state, setState] = useMergeState(initialState)

  const fetchData = async (url, options) => {
    if (!url) return

    const mergedOptions = {
      ...defaultOptions,
      ...options
    }

    const strapiURL = getStrapiURL(url)
    setState({ isLoading: true })
    const response = await fetch(strapiURL, mergedOptions)

    const body = await response.json()
    if (response.ok) {
      setState({ data: body.data, meta: body.meta, error: null, isLoading: false })
    } else {
      setState({
        data: null,
        meta: null,
        error: response.statusText || body.error.message,
        isLoading: false
      })
    }
    return { response, body }
  }

  return { ...state, fetchData }
}
