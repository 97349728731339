import { fetchAPI } from 'utils/api'
import {
  createGetCategoriesEndpoint,
  createGetIndividualsEndpoint,
  createGetIndustriesEndpoint,
  createGetTechnologiesEndpoint
} from 'utils/endpoints'

export const getCategories = async () => {
  try {
    const categories = await fetchAPI(createGetCategoriesEndpoint({ populate: '' }))
    return categories?.data || []
  } catch {
    return []
  }
}

export const getTechnologies = async () => {
  try {
    const technologies = await fetchAPI(createGetTechnologiesEndpoint({ populate: '' }))
    return technologies?.data || []
  } catch {
    return []
  }
}

export const getIndividuals = async () => {
  try {
    const individuals = await fetchAPI(createGetIndividualsEndpoint({ populate: '' }))
    return individuals?.data || []
  } catch {
    return []
  }
}

export const getIndustries = async () => {
  try {
    const industries = await fetchAPI(createGetIndustriesEndpoint({ populate: '' }))
    return industries?.data || []
  } catch {
    return []
  }
}
